import { createApp } from "vue"
import App from "./App.vue"
import router from "./router/index"
import { createPinia } from "pinia"
import piniaPluginPersistedstate from "pinia-plugin-persistedstate"
// 引入svg icon插件
import initSvgIcon from "./icons/index"
import "virtual:svg-icons-register"
import installElementPlus from "./plugins/element"
// import setupPermission from "@/router/permission"
import setupDirective from "./directive"
import "normalize.css/normalize.css"
// 全局css
import "@/styles/index.scss"
//  import "./styles/index.scss"
//引入并设置ren文件大幅度发
import {useREM} from "./utils/flexible"
// 设置 rem
useREM()
//涌入图片懒加载
import VueLazyload from 'vue-lazyload'
// import Vconsole from 'vconsole'
// // 本地调试时,显示Vconsole
// new Vconsole()
// console.log("%c Line:25 🍩 import.meta", "color:#7f2b82", import.meta);


const app = createApp(App)

app.use(VueLazyload)
// pinia
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(pinia)

app.use(router)
// 路由权限控制
// setupPermission()

// 指令
setupDirective(app)

// 使用svg icon组件
app.use(initSvgIcon)

// element plus
app.use(installElementPlus)

app.mount("#app")

