import {  ref, reactive, nextTick,onMounted,watch } from "vue";
import { useRouter,useRoute } from "vue-router";
let $router = useRouter();
let activeIndex = ref<string | number>(0); // 一级菜单选中索引值
export default function (getrouter) {
    if (getrouter === '/home') {
        activeIndex.value = 0
      } else if (getrouter === '/nowmessage' || getrouter === '/work' || getrouter === '/development'
       || getrouter === '/centre' || getrouter === '/operation' || getrouter === '/moblebank'
        || getrouter === '/moblebusiness' || getrouter === '/personbank' || getrouter === '/companybank') {
          activeIndex.value = 1
      } else if (getrouter === '/trynow') {
        activeIndex.value = 2
      } else if (getrouter === '/channel' || getrouter === '/laws'|| getrouter === '/use'
      || getrouter === '/promise') {
        activeIndex.value = 3
      } else if (getrouter === '/ecologycooperation') {
        activeIndex.value = 4
      }else if (getrouter === '/companyprofile') {
        activeIndex.value = 5
      }else if (getrouter === '/news') {
        activeIndex.value = 6
      } 
      return  activeIndex.value
}