<template>
  <el-config-provider :locale="zhCn">
     <SideRightBar/>
   <Header/>
   <div class="content">
     <router-view></router-view>
   </div>
    <Footer v-if="isShow"></Footer>
  </el-config-provider>
</template>

<script lang="ts" setup>
import Header from '@/components/Header/index.vue'
import Footer from '@/components/Footer/index.vue'
import SideRightBar from '@/components/SideRightBar/index.vue'
import zhCn from "element-plus/dist/locale/zh-cn.mjs"
import { storeToRefs } from "pinia"
import { useAppStore } from "./stores/app"
import { useGenerateTheme } from "@/hook/useGenerateTheme"
import {  ref } from "vue";
useGenerateTheme()
const store = useAppStore()
const isShow = ref(false)
setTimeout(() => {
  isShow.value = true
},6000)
</script>

<style>

#app {
  --el-form-label-font-size:.0972rem;
  --font-size:.0972rem;
  --el-popover-padding: .0833rem;
   --el-popover-padding-large: .125rem .1389rem;
   --el-popover-title-font-size: .1111rem;
   --el-popover-border-radius: .0278rem;
  --contexify-menu-minWidth: 1.5278rem;
  --contexify-menu-padding: .0417rem;
  --contexify-menu-radius: .0417rem;
  --contexify-separator-margin: .0347rem;
  --contexify-itemContent-padding: .0417rem;
  --contexify-activeItem-radius: .0278rem;
  --el-font-size-large: .125rem;
  --el-font-size-medium: .1111rem;
  --el-font-size-base: .0972rem;
  --el-font-size-small: .0903rem;
  --el-font-size-extra-small: .0833rem;
  --el-font-line-height-primary: .1667rem;
  --el-border-radius-base: .0278rem;
  --el-border-radius-small: .0139rem;
  --el-border-radius-round: .1389rem;
  --el-component-size-large: .2778rem;
  --el-component-size: 25px;
  --el-component-size-small: .1667rem;
  .el-input__wrapper {
    padding: .0069rem .0833rem; 
 }
 .el-form {
   --el-form-inline-content-width: 1.5278rem !important;
 }

 .el-checkbox {
  --el-checkbox-font-size: .0972rem;
  --el-checkbox-input-height: .0972rem;
  --el-checkbox-input-width: .0972rem;
 }
 .el-input__inner{
 
 }
}
</style>

<style lang="scss" scoped>
// .el-input__wrapper {
//    padding: .0069rem .1042rem; 
// }
// .el-form {
//   --el-form-inline-content-width: 1.5278rem !important;
// }
// .el-popover {
//   --el-popover-padding: .0833rem;
//   --el-popover-padding-large: .125rem .1389rem;
//   --el-popover-title-font-size: .1111rem;
//   --el-popover-border-radius: .0278rem;
// }
.content {
  min-height: calc(100% - 3.4725rem);
  overflow: hidden;
}

</style>




