export const PriceRoute = [
    {
      path: "/CherryMe",
      name: "CherryMe",
      component: () => import("../../views/price/Channel/index.vue"),
      meta: {
        title: "CherryMe移动办公",
      },
    },
    {
      path: "/CherryMPS",
      name: "CherryMPS",
      component: () => import("../../views/price/CherryMPS/index.vue"),
      meta: {
        title: "CherryMPS移动开发平台",
      },
    },
    {
      path: "/laws",
      name: "laws",
      component: () => import("../../views/price/Laws/index.vue"),
      meta: {
        title: "法律声明", 
      },
    },
    {
      path: "/use",
      name: "use",
      component: () => import("../../views/price/Use/index.vue"),
      meta: {
        title: "使用声明",
      },
    },
    {
      path: "/promise",
      name: "promise",
      component: () => import("../../views/price/Promise/index.vue"),
      meta: {
        title: "合法用途承诺",
      },
    },
    
  ];
  