export const useREM = () => {
  function getFontsize() {
    let html = document.documentElement
    //     // 当前页面宽度相对于 1920宽的缩放比例，可根据自己需要修改。
    const scale = document.documentElement.clientWidth
    console.log("%c Line:6 🌰 scale", "color:#ffdd4d", scale);
    // 定义根元素（html）fontSize 的大小 （rem）
    html.style.fontSize = scale / 10 + 'px'
    console.log("%c Line:9 🍑 html.style.fontSize", "color:#b03734", html.style.fontSize);
    // console.log("@@@@@@@@ document.documentElement.style.fontSize", document.documentElement.style.fontSize);
  }

  document.addEventListener('DOMContentLoaded', getFontsize)

  // 监听 html 文档被解析完成的事件
  window.addEventListener('resize', getFontsize)
  window.resizeTo(document.documentElement.clientWidth, document.documentElement.clientHeight)
}
