export const ProductIntroductionRoute = [
  {
    path: "/nowmessage",
    name: "nowmessage",
    component: () => import("../../views/productintroduce/message/index.vue"),
    meta: {
      title: "传信及时通讯",
    },
  },
  {
    path: "/work",
    name: "work",
    component: () => import("@/views/productintroduce/Work.vue"),
    meta: {
      title: "传信移动办公",
      icon: "list",
      noCache: true,
    },
  },
  {
    path: "/development",
    name: "development",
    component: () => import("@/views/productintroduce/DevelopmentPlatform.vue"),
    meta: {
      title: "传信开发平台",
      icon: "list",
      noCache: true,
    },
  },
  {
    path: "/centre",
    name: "centre",
    component: () => import("@/views/productintroduce/CentrePlatform.vue"),
    meta: {
      title: "传信移动中台",
      icon: "list",
      noCache: true,
    },
  },
  {
    path: "/operation",
    name: "operation",
    component: () => import("@/views/productintroduce/OperationPlatform.vue"),
    meta: {
      title: "传信运营平台",
      icon: "list",
      noCache: true,
    },
  },
  {
    path: "/moblebank",
    name: "moblebank",
    component: () => import("../../views/productintroduce/MobleBank/index.vue"),
    meta: {
      title: "传信手机银行",
    },
  },
  {
    path: "/moblebusiness",
    name: "moblebusiness",
    component: () => import("../../views/productintroduce/MobleBusiness/index.vue"),
    meta: {
      title: "传信移动展业",
    },
  },
  {
    path: "/personbank",
    name: "personbank",
    component: () => import("../../views/productintroduce/PersonBank/index.vue"),
    meta: {
      title: "个人网银",
    },
  },
  {
    path: "/companybank",
    name: "companybank",
    component: () => import("../../views/productintroduce/CompanyBank/index.vue"),
    meta: {
      title: "企业网银",
    },
  },
  
];
